import React from "react";

// Customizable Area Start
import { Link } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Card, CardContent, CardMedia, Typography, Grid
} from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { landingBgImage, arrowRight, spark60, phone24, email24, insta24, logo } from "./assets";
// Customizable Area End
import LandingPageController, {
    Props
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEventCard = (data: any) => {
    const { title,  location, start_time,start_date, event_posters, end_date, end_time } = data.attributes;
    const addrress = this.getLocation(location)
    const imageUrl = event_posters[0]?.url;

    return(
        <div style={webStyle.cardContainer} className="card-container">
          <Card style={webStyle.card} className="card">
            <CardMedia
                component="img"
                alt={title}
                height="281"
                width="281"
                image={imageUrl}
                style={{borderRadius: "16px"}}
            />
            <CardContent>
                <Typography style={webStyle.cardTitle} className="cardTitle" gutterBottom variant="h6" component="div">{title}<br/> <span style={{color: data.categoryClr}}>Location Name/Venue</span></Typography>
                <Grid container style={webStyle.cardIconContainer} >
                  <span><LocationOnIcon style={webStyle.cardIcon} /></span>
                  <Typography style={webStyle.cardIconTxt} variant="body2"> {addrress} </Typography>
                </Grid>
                <Grid style={webStyle.cardIconContainer} container>
                  <AccessTimeIcon style={webStyle.cardIcon}/>
                  <Typography  style={webStyle.cardIconTxt}  variant="body2"> {`${start_date}, ${start_time} - ${end_date}, ${end_time}`} </Typography>
                </Grid>
                <Grid container style={webStyle.cardFooter}>
                  <div style={{background: data.categoryClr || "gray", color: "white", height: "27px",padding:"0px 5px", borderRadius:"20px", alignContent: "center"}} className="">Categoty</div>
                  <div style={{color: data.categoryClr, textDecoration: "underline"}} className="">See More</div>
                </Grid>
            </CardContent>             
          </Card>
        </div>
    )
  }
  renderTodaySection = () => {
    
    return(
      <div style={webStyle.happeningTodaySection} className="happening-today-section">
        <h3 style={webStyle.happeningTodaySectionTitle} className="title">HAPPENING <span><img src={spark60}/></span> TODAY</h3>
        <div style={webStyle.eventCardSection} className="event-card-section">
            {this.state.events.map((event:any, index:number) => (
                <React.Fragment key={index}>
                {this.renderEventCard(event)}
                </React.Fragment>
            ))}
        </div>        
      </div>
    )

  }
  renderCalenderSection = () => {

    return(
      <div style={webStyle.calenderSection} className="calender-section">
          <h5 style={webStyle.calenderTitle}>Welcome to the Gayest City in the World!</h5>
          <div style={webStyle.buttonGroup} className="button-group">
            <span style={webStyle.calenderBtnOrange} className="btn-orange">Map</span>
            <Link style={{textDecoration: "unset"}} to="/events"><span style={webStyle.btnBorderOrange}>Events</span></Link>
          </div>
      </div>
    )

  }
  renderFooterSection = () => {
    return(
      <div>
        <Grid style={webStyle.footerSection1} container>
        <a href="tel:+14844578416" style={{textDecoration: "unset"}}>
          <div style={webStyle.footerIconContainer}><span style={webStyle.iconContainer}><img src={phone24}/></span> +14844578416</div>
        </a>
        <a href="mailto:support@sparkdc.com" style={{textDecoration: "unset"}}>
          <div style={webStyle.footerIconContainer}><span style={webStyle.iconContainer}><img src={email24}/></span> support@sparkdc.com</div>
        </a>
        <a href="https://www.instagram.com/" style={{textDecoration: "unset"}} target="_blank" rel="noopener noreferrer">
          <div style={webStyle.footerIconContainer}><span style={webStyle.iconContainer}><img src={insta24}/></span> @sparklgbtq</div>
        </a>
        </Grid>
        <Grid style={webStyle.footerSection2} container>
          <Link style={webStyle.footerLink} to="/">Terms of Use</Link>
          <Link data-test-id="linkPrivacy" style={webStyle.footerLink} to="/">Privacy Policy</Link>
        </Grid>

      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <div className="positionRelative" style={webStyle.landingStyle}>
                <div style={webStyle.overLay}></div>
                <div style={webStyle.landingLogoContent} className="landing-logo-content">
                  <div style={webStyle.landingContentWithLogo} className="">
                    <div className="">
                      <h3 style={webStyle.socialTitle}>SPARK<span style={webStyle.socialTitleDc}>DC</span></h3>
                      <hr style={webStyle.socialTitelHr}/>
                      <h5 style={webStyle.socialDescription}>LGBTQ+ <span style={{fontFamily: 'Just Me Again Down Here',fontSize: "45px", lineHeight: "10px", textAlign: "center"}}>Community</span></h5>
                      <Link style={webStyle.btnOrange} className="btn-orange" to="/">Explore LGBTQ Events <span style={webStyle.rightArrowSpan}><img src={arrowRight} /></span></Link>
                    </div>                   
                    <img data-test-id="logoImage" style={webStyle.landingContentIcon}  src={logo} />
                  </div>
                    
                </div>
                
            </div>
            {this.renderCalenderSection()}
            {this.renderTodaySection()}
            {this.renderFooterSection()}
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle: { [key: string]: React.CSSProperties } = {
  landingLogoContent: { margin: 'auto', textAlign: 'center', zIndex: 1 },
  landingContentWithLogo: { display: "flex", flexDirection: "row"},
  landingContentIcon: {height: "81px", marginTop: "71px"},
  btnOrange: { color: 'white', display: 'flex', fontFamily: 'Inter', padding:"0px 15px 0px 32px", justifyContent: 'space-between', alignItems: 'center', textDecoration: 'none', width: '328px', height: '72px', borderRadius: '100px', backgroundColor: '#F47133', fontSize: '25px', fontWeight: 400, margin: 'auto' },
  rightArrowSpan: {background: "#E1682F", width:"51px", height: "51px", borderRadius:"50%", alignContent:"center" },
  calenderSection: { display: 'flex', flexDirection: 'column', justifyContent: 'center',gap: "44px", margin: '59px', textAlign: "center"},
  calenderContent: { marginTop: 'auto', marginBottom: 'auto', display: 'flex', flexDirection: 'column', gap: '15px', maxWidth: '457px' },
  buttonGroup: { display: 'flex', flexDirection: 'row', gap: '15px', margin:"auto" },
  btnBorderOrange: { fontSize: '18px', fontFamily: 'Inter', fontWeight: 400, lineHeight: '10px', width: '206px', height: '38px', borderRadius: '100px', border: '1px solid #F47133', color: '#F47133', display: 'flex', justifyContent: 'center', alignItems: 'center'},
  calenderBtnOrange: {fontFamily: 'Inter', width: "206px",height: "38px", borderRadius: "100px",  background: "#F47133", fontSize: "18px", fontWeight: "400", lineHeight: "10px", textAlign: "left", color: "white", display: "flex", justifyContent: "center", alignItems: "center"},
  eventsBtnRed:{fontFamily: 'Inter', width: "206px",height: "38px", borderRadius: "100px",  background: "#D62339", fontSize: "18px", fontWeight: "400", lineHeight: "10px", textAlign: "left", color: "white", display: "flex", justifyContent: "center", alignItems: "center"},
  happeningTodaySection: { fontFamily: 'Inter', textAlign: 'center', display: "flex", flexDirection: "column" },
  happeningTodaySectionTitle: { fontSize: '45px', fontWeight: 700, background: 'linear-gradient(90deg, #045E81 0%, #3C8D9E 13%, #96B999 27%, #D5E07A 44%, #E9A64D 55%, #F47133 63%, #BC3081 83%, #D62339 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', display:"flex", margin: "0px auto 57px auto"},
  overLay: {background: "linear-gradient(180deg, rgba(28, 27, 31, 0.3) 0%, #1C1B1F 100%)", position: "absolute", width: "100%", height: "100%", top:"0", left:"0" },
  socialTitle: {fontFamily: 'Inter',fontSize: "100px",fontWeight: "600", margin: "0px", lineHeight: "1"},
  socialTitelHr: {height: "3px", background: "white"},
  socialTitleDc: {fontFamily: 'Inter',fontSize: "40px",fontWeight: "600", margin: "0px"},
  socialDescription: {fontFamily: 'Inter',fontSize: "35px",fontWeight: "400", margin:"0", height:"46px", paddingBottom:"34px"},
  calenderTitle:{fontFamily:"Inter", fontSize: "45px", fontWeight: "700", lineHeight: "45px", margin: "0px" },
  landingStyle: { 
    position: 'relative' as 'relative',
    backgroundImage: `url(${landingBgImage})`,
    minHeight: "617px",
    width: "100%",
    marginTop: "-81px",
    display: "flex",
    color:"white",
    alignItems: "auto",
  },
  eventCardSection: { display: 'flex', flexDirection: 'row', gap: "24px", justifyContent: "center", marginBottom: "66px" },
  cardContainer: { width: '281px', position: 'relative', borderRadius: '16px', color: 'white' },
  card: { position: 'relative', borderRadius: '16px', height: "auto" },
  cardTitle: { fontFamily: 'Inter',fontSize: '16px', fontWeight: 600, lineHeight: '25px', textAlign: "left",marginBottom:'15px' },
  cardFooter: {display: 'flex', justifyContent: 'space-between'},
  addToFav: { position: 'absolute', top: '8px', right: '8px', background: '#E2E8F0', color: 'white', zIndex: 1 },
  cardIcon: { background: '#E2E8F0', padding: "5px", fontSize: "20px", borderRadius: "50%"},
  cardIconContainer: { display: "flex", flexWrap:"unset",gap: "10px", marginBottom:"16px"},
  cardIconTxt: {fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '15px', textAlign: 'left'},
  footerSection1:{background: "#045E81", display: "flex", justifyContent:"space-around", height: "101px", alignContent: "center", color: "white"},
  footerSection2:{background: "#043C61",display: "flex", justifyContent:"center", height: "57px", alignContent: "center", color: "white", gap: "25px"},
  footerLink: {color: 'white', display: 'flex', fontFamily: 'Inter', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', fontSize: '18px', fontWeight: 400,},
  footerIconContainer: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "10px",
    color:"white",
    textDecoration:"unset"
  },
  iconContainer:{background: 'white', padding: "7px", fontSize: "20px", borderRadius: "50%"}
};
// Customizable Area End

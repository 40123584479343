import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface Location {
  lat: number;
  lng: number;
  color: string;
}

interface EventData {
  date: string;
  events: number;
  locations: Location[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedDate: string;
  eventData: Location[];
  selectedEvents: string[];
  selectedCommunities: string[];
  searchQuery: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EventScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      selectedDate: "",
      eventData: [{ lat: 52.3676, lng: 4.9041, color: 'red' }],
      selectedEvents: [],
      selectedCommunities: [],
      searchQuery: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  handleEventChange = (event: any) => {
    const { value } = event.target;
    this.setState({
      selectedEvents: typeof value === 'string' ? value.split(',') : value,
    });
  };

  handleCommunityChange = (event: any) => {
    const { value } = event.target;
    this.setState({
      selectedCommunities: typeof value === 'string' ? value.split(',') : value,
    });
  };

  handleSearchChange = (event: any) => {
    this.setState({ searchQuery: event.target.value });
  };

  // Customizable Area End
}

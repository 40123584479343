export const landingBgImage = require("../assets/homebg.png");
export const landingSecondaryImage = require("../assets/secondaryImg.jpeg");
export const logo = require("../assets/logo1.png");
export const ellipse = require("../assets/Ellipse.png");
export const insta = require("../assets/Instagram.png");
export const favIcon = require("../assets/fav.png");
export const locationIcon = require("../assets/location24.png");
export const timeIcon = require("../assets/time24.png");
export const spark60 = require("../assets/spark60.png");
export const arrowRight = require("../assets/arrowRight24.png");
export const insta24 = require("../assets/Instagram24.png");
export const phone24 = require("../assets/phone24.png");
export const email24 = require("../assets/mail24.png");

import React from "react";
import { StyleSheet } from "react-native";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import {logo, insta} from "../../blocks/landingpage/src/assets"

const TopNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname;
  const navStyle = {fontFamily: "Inter",textTransform: "unset", fontSize: "18px", fontWeight:currentLocation == "/"?"400": "700", lineHeight: "10px", textAlign: "left", padding: "0", textWrap: "nowrap"}
  const navStyleAboutUs = {fontFamily: "Inter",textTransform: "unset", fontSize: "18px", fontWeight: "400", lineHeight: "10px", textAlign: "left", padding: "0", textWrap: "nowrap"}
  const navBackground =  currentLocation == "/"? "" : "#043C61";
  return (
    <Box style={{color: "white",display: 'flex', justifyContent: 'space-between',padding: '0 104px 0 104px', zIndex: '1',height: '105px', backgroundColor: navBackground}} className="navContainer">
      <div style={{display: 'flex',flexDirection: 'row', gap: '45px'}} className="section1">
        <Button style={navStyle} className="navLink" color="inherit" component={Link} to="/events">LGBTQ Events</Button>
        <Button style={navStyleAboutUs} className="" color="inherit" component={Link} to="/">About Us</Button>
      </div>
      <img src={logo} alt="logo" />
      <div style={{display: 'flex',flexDirection: 'row', gap: '45px'}} className="section2">
        <Button style={navStyle} className="navLink" color="inherit" component={Link} to="/login">Login</Button>
        <img style={{marginTop: "auto", marginBottom: "auto", height: "31px", width: "31px", borderRadius: "50%", backgroundColor: "white"}} className="instaIcon" src={insta} alt="insta" />
      </div>
    </Box>
  );
};

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#cccccc"
  }
});

export default TopNav;

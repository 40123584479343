import React from "react";

// Customizable Area Start
import { Link } from 'react-router-dom';
import {
  Grid,  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  TextField,
  Button,
  Box,
  InputAdornment,
} from '@mui/material';
import {
  createTheme,
} from "@mui/material/styles";
import {  phone24, email24, insta24 } from "../../landingpage/src/assets";
import EventScreenController from "./EventScreenController.web";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import SearchIcon from '@mui/icons-material/Search';
import Calendar from 'react-calendar';
// Customizable Area End

export default class EventsScreen extends EventScreenController {
   

  // Customizable Area Start
  renderEventSearchForm = () => {
    const { selectedEvents, selectedCommunities, searchQuery } = this.state;
    const eventOptions = [
      'Be entertained',
      'Celebrate',
      'Create',
      'Dance',
      'Drink',
      'Eat',
      'Exercise',
      'Explore',
    ];
    
    const communityOptions = [
      'Community A',
      'Community B',
      'Community C',
      'Community D',
      'Community E',
    ];
    const placeHolderStyle = {fontFamily: 'Inter', fontSize: '18px', fontWeight: '400' }
    return (
      <Box display="flex" alignItems="center" gap={2} p={2} style={{padding: "20px 60px"}}>
        <FormControl variant="outlined" style={{ minWidth: 310 }}>
          <Select
            multiple
            value={selectedEvents}
            onChange={this.handleEventChange}
            style={{height: "50px", borderRadius:"100px", color:"#888888"}}
            displayEmpty
            placeholder="Event Type"        
            renderValue={(selected) => {
              if (selectedEvents.length > 0) {
                return selected.join(', ');
              }
              return <span style={placeHolderStyle}>Event Type</span>
            }}
          >
            {eventOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={selectedEvents.indexOf(option) > -1} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ minWidth: 296 }}>
          <Select
            multiple
            value={selectedCommunities}
            displayEmpty
            onChange={this.handleCommunityChange}
            style={{height: "50px", borderRadius:"100px", color:"#888888"}}
            renderValue={(selected) => {
              if (selectedCommunities.length > 0) {
                return selected.join(', ');
               
              }
              return <span style={placeHolderStyle}>Communities</span>
            }}
          >
            {communityOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={selectedCommunities.indexOf(option) > -1} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={webStyle.searchContainer}>
          <TextField
            style={{maxHeight: "50px", borderRadius:"100px", border:"none"}}
            variant="standard"
            placeholder="Search"           
            sx={{
              border: 'none',
            }}
            value={searchQuery}
            onChange={this.handleSearchChange}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment style={{height: "38px", width: "38px", borderRadius:"50%", background: "#F47133",color:"white",display:"flex", justifyContent:"center"}} position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button style={{width: '206px', height: "38px", borderRadius: "100px",background: "#F47133", color: "white", marginLeft: "auto"}}>
            Search
          </Button>          
        </div>
      </Box>
    );
  };
  renderSearchScreen = () => {
    const containerStyle = {
      width: '100%',
      height: '100vh',
    };

    const center = {
      lat: 52.3676, // Center around Amsterdam
      lng: 4.9041,
    };

    const calendarStyle = {
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
      padding: '10px',
    };

    return(
      <div style={{ display: 'flex',padding: "20px 60px" }}>
      <div style={{ flex: 2, height: '100vh', maxWidth:"687px", maxHeight:"736px", borderRadius:"20px",overflow:"hidden", background:"gray"}}>
        <LoadScript googleMapsApiKey="">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
          >
            {this.state.eventData.map((location, index) => (
              <Marker
                key={index}
                position={{ lat: location.lat, lng: location.lng }}
                icon={{
                  path:2.0,
                  fillColor: location.color,
                  fillOpacity: 1,
                  strokeWeight: 0,
                  scale: 10,
                }}
              />
            ))}
          </GoogleMap>
        </LoadScript>
      </div>
      <div style={{ flex: 1, height: '100vh', maxWidth:"623px", maxHeight:"736px", borderRadius:"20px", padding: '20px' }}>
        <div style={webStyle.sortByDropdownContainer} >
          <div>
            <p style={webStyle.eventsFound}>Events found</p>
            <p style={webStyle.sortByRelevance}>Sort By: <span style={webStyle.sortBy}>Relevance</span></p>
          </div>
          <div>
            <p style={webStyle.shareEvent}>Share Your Event <span style={webStyle.shareEventIcon}></span></p>
            <p style={webStyle.results}>750 Results</p>
          </div>

        </div>
        <div style={calendarStyle}>
          <Calendar  value={new Date()} />
        </div>
      </div>
    </div>
    )
  }
  renderFooterSection = () => {
    return(
      <div>
        <Grid style={webStyle.footerSection1} container>
          <a href="tel:+14844578416" style={{ textDecoration: "unset" }}><div style={webStyle.footerIconContainer}><span style={webStyle.iconContainer}><img src={phone24}/></span> +14844578416</div></a>
          <a href = "mailto:support@sparkdc.com" style={{textDecoration: "unset"}}><div style={webStyle.footerIconContainer}><span style={webStyle.iconContainer}><img src={email24}/></span> support@sparkdc.com</div></a>
          <a href="https://www.instagram.com/" style={{textDecoration: "unset"}} target="_blank" rel="noopener noreferrer"><div style={webStyle.footerIconContainer}><span style={webStyle.iconContainer}><img src={insta24}/></span> @sparklgbtq</div></a>
        </Grid>
        <Grid style={webStyle.footerSection2} container>
          <Link style={webStyle.footerLink} to="/">Terms of Use</Link>
          <Link data-test-id="linkPrivacy" style={webStyle.footerLink} to="/">Privacy Policy</Link>
        </Grid>

      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
     <>
     {this.renderEventSearchForm()}
     {this.renderSearchScreen()}
     {this.renderFooterSection()} 
     </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const webStyle: { [key: string]: React.CSSProperties } = {
  footerSection1 : {background: "#045E81", display: "flex", justifyContent:"space-around", height: "101px", alignContent: "center", color: "white"},
  footerSection2 : {background: "#043C61",display: "flex", justifyContent:"center", height: "57px", alignContent: "center", color: "white", gap: "25px"},
  footerLink : {color: 'white', display: 'flex', fontFamily: 'Inter', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', fontSize: '18px', fontWeight: 400,},
  footerIconContainer: { display: "flex", gap: "15px", alignItems: "center", fontFamily: "Inter", fontSize: "18px",
    lineHeight: "10px",
    fontWeight: "600"
  },
  iconContainer: { background: 'white', padding: "7px", fontSize: "20px", borderRadius: "50%"},
  searchContainer :{border: "1px solid #888888", borderRadius: "100px", maxHeight: "50px", padding: "6px 6px 6px 13px", display: "flex", alignItems: "center", width: "667px"},
  sortByDropdownContainer : {display: "flex", flexDirection: "row", justifyContent: "space-between"},
  eventsFound : {background: 'linear-gradient(90deg, #045E81 0%, #3C8D9E 13%, #96B999 27%, #D5E07A 44%, #E9A64D 55%, #F47133 63%, #BC3081 83%, #D62339 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent',fontFamily : "Inter", fontSize: "22px", fontWeight: "600",margin:"0"},
  calenderStyle: {
    border: "none",
    width:"100%"
  },
  sortByRelevance:{fontFamily: "Inter", fontSize: "16px", fontWeight: "600", marginTop: "0"},
  sortBy:{fontFamily: "Inter", fontSize: "16px", fontWeight: "600",color: "#F47133"},
  shareEvent:{fontFamily: "Inter", width: "211px", height: "38px",borderRadius: "100px", margin:"0",background: "#D62339", color: "white", textAlign:"center", alignContent: "center"},
  shareEventIcon: {height: "28px",width:"28px", borderRadius: "50%",backgroundColor:"white"},
  results: {fontFamily: "Inter", fontSize: "16px", fontWeight: "400",lineHeight: "10px", textAlign:"end", marginTop:"10px"}
};
// Customizable Area End
